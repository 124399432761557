import React, { useEffect, useRef, useState } from 'react'
import { useStateMachine } from 'little-state-machine'
import { Link, navigate } from 'gatsby'

import { updateDetails } from './updateDetails'
import HubspotForms from '../Hubspot/HubspotForms'
import { isBrowser } from '../helpers/helpers' 

import * as SignUpStyles from './signup.module.scss'
import './signupForm.scss'

const InviteOnlyEmailForm = ({ inviteToken, companyName }) => {

  const [createAccount, setCreateAccount] = useState(false)
  const { actions } = useStateMachine({ updateDetails })

  // Return a DOM reference to the email field of the embedded HubSpot form.
  const getHubSpotEmailField = () => {
    const form = document.getElementById(`hsForm_${process.env.GATSBY_HUBSPOT_INVITE_SIGNUP_FORM_ID}`)
    return form.querySelector(`#email-${process.env.GATSBY_HUBSPOT_INVITE_SIGNUP_FORM_ID}`)
  }

  // Attach an event listener once when the component is rendered to cater for
  // the HubSpot form API and attaching to it's lifecycle events. We can't do
  // this directly via the options properties onFormReady etc because they
  // don't have the expected jquery dependnecy in place. This event listener,
  // while ugly, avoids us having to embed another version of jquery.
  const email = useRef()
  const hsFormSubmitting = useRef(false)
	useEffect(() => {
    if (isBrowser()) {

      const handleHubspotFormEvents = event => {
        if (!event.data.type === 'hsFormCallback') return

        switch (event.data.eventName) {
          case 'onFormSubmit': 
            const emailField = getHubSpotEmailField()
            email.current = emailField.value
            hsFormSubmitting.current = true
            break;
          case 'onFormSubmitted':
            actions.updateDetails({ email: email.current })
            navigate(`/sign-up/welcome?inviteToken=${inviteToken}&companyName=${companyName}`)
            break;
        }
      }

      window.addEventListener('message', handleHubspotFormEvents)

      return () => {
        window.removeEventListener('message', handleHubspotFormEvents)
      }
		}
	},[])

  return (
    <div className='component'>
      <h1>Welcome to Loaded Reports</h1>

      {!createAccount &&
      <div className='invite-header'>
        <div className={`${SignUpStyles.tCenter} ${SignUpStyles.mb16}`}>You've been invited to access <strong>{companyName}</strong>.</div>
        <div className={`${SignUpStyles.tCenter} ${SignUpStyles.mb16}`}>To accept this invite, you must be logged in.</div>
        <div className={SignUpStyles.buttonRow}>
          <Link to={`/login?inviteToken=${inviteToken}`} className={`${SignUpStyles.button} ${SignUpStyles.open}`} >Login To Existing Account</Link>
          <div className={`${SignUpStyles.button} ${SignUpStyles.open}`} onClick={() => setCreateAccount(true)}>Create Account</div>
        </div>
      </div>
      }

      {createAccount &&
      <div className={SignUpStyles.emailForm}>
        <div className={SignUpStyles.formDiv}>
          <div className={`${SignUpStyles.tCenter} ${SignUpStyles.mb16}`}>
            Please enter your email address. This will be the address you log in with in the future.
          </div>
          {/* The visibility style here is a workaround to a render quirk with the HubSpot form.
              When it's submitted the jquery code hides the form, but then it gets re-rendered
              by React and ends up visible for a brief flash before we transition to the second
              stage 'welcome' form. We hide the form when we've detected a form submission via
              CSS here. */}
          <div style={{ visibility: hsFormSubmitting.current === true ? 'hidden' : 'visible' }}>
            <HubspotForms
              formID={process.env.GATSBY_HUBSPOT_INVITE_SIGNUP_FORM_ID}
              blockID={`form-${process.env.GATSBY_HUBSPOT_INVITE_SIGNUP_FORM_ID}`}
              formOptions={{
                locale: 'en',
                translations: {
                  en: {
                    required: 'A valid email address is required'
                  }
                }
              }}
              formHeight='112px'
            />
          </div>
        </div>
      </div>
      }

      <div className={SignUpStyles.disclaimer}>
        By creating a loaded account I agree to the <Link to='/end-user-licence-agreement'>End User Licence Agreement</Link> and <Link to='/privacy-policy'>Privacy Policy.</Link>
      </div>
    </div>
  )
}

export default InviteOnlyEmailForm
