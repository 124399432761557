import React, { useEffect, useState } from 'react'
import queryString from 'query-string'

import Base from '../components/templates/Base'
import EmailForm from '../components/SignUp/EmailForm'
import InviteOnlyEmailForm from '../components/SignUp/InviteOnlyEmailForm'

import * as SignUpStyles from '../components/SignUp/signup.module.scss'

const SignUpPage = ({ location }) => {

  const [isInviteSignup, setIsInviteSignup] = useState(false)
  const [token, setToken] = useState()
  const [company, setCompany] = useState()

  // To avoid causing rehydration breaking in unpredictable ways it's important
  // that the initial browser render of this component is exactly the same as
  // the server side rendered version of this component. This component renders
  // a different path based on whether there is a URL parameter present, so we
  // only check for that after the initial render by changing state after it's
  // mounted. See https://reactjs.org/docs/react-dom.html#hydrate for details.
  useEffect(() => {

    const search = location.search ? queryString.parse(location.search) : false;
    const { inviteToken, companyName } = queryString.parse(location.search);

    setIsInviteSignup(!!inviteToken)
    setToken(inviteToken)
    setCompany(companyName)

  })

  return (
    <Base>
      <section className={SignUpStyles.signUp}>

          {isInviteSignup &&
          <div className={`${SignUpStyles.innerDiv} signup-invite`}>
            <InviteOnlyEmailForm inviteToken={token} companyName={company} />
          </div>
          }

          {!isInviteSignup &&
          <div className={`${SignUpStyles.innerDiv} signup-lead`}>
            <EmailForm location={location} />
          </div>
          }

      </section>
    </Base>
  )
}

export default SignUpPage